import {
  HeroSection,
  PartnersSection,
  IntroSection,
  AuditSection,
  NetworkSection,
} from '@/components/home';
import PropTypes from 'prop-types';
import Head from 'next/head';
import axios from 'axios';

const propTypes = {
  liveMetrics: PropTypes.shape(),
};

const title = 'Boosting Web3 Security Smart Contract Audits | Trustblock';
const description =
  'The ultimate web3 security data hub. Find your next smart contract auditor thanks to metrics & boost your product with our security data API & labels.';

function Home({ liveMetrics }) {
  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="twitter:title" content={title} />
        <meta property="twitter:description" content={description} />
        <link rel="canonical" href="https://trustblock.run" />
      </Head>
      <HeroSection liveMetrics={liveMetrics} />
      <PartnersSection />
      <AuditSection />
      <IntroSection />
      <NetworkSection />
    </>
  );
}

Home.propTypes = propTypes;
export default Home;

export async function getStaticProps() {
  const response = await axios({
    method: 'get',
    url: `${process.env.NEXT_PUBLIC_API_URL}/ui/landing`,
  });

  return {
    props: {
      liveMetrics: response.data,
    },
    revalidate: 84600,
  };
}
